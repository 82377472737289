export default {
  adminmenus: [
    {
      name: '首页',
      url: '/',
      permission: null,
      icon: 'el-icon-monitor'
    },
    {
      name: '订单管理',
      url: '',
      permission: 'Order',
      icon: 'el-icon-shopping-cart-1',
      children: [
        {
          name: '租赁订单',
          url: '/order/list',
          permission: 'Order.ChargingOrderManage'
        },
        // {
        //   name: '反馈列表',
        //   url: '/order/feedbacks',
        //   permission: 'Order.ChargingOrderAbnormalityManage'
        // },
        {
          name: '商城订单',
          url: '/device/mallorders',
          permission: 'Order.ShoppingOrderManage'
        }
      ]
    },
    {
      name: '财务管理',
      permission: 'Financial',
      icon: 'icon iconfont icon-caiwu',
      children: [
        {
          name: '财务统计',
          url: '/finance/statistics',
          permission: 'Financial.Statistics'
        },
        {
          name: '提现管理',
          url: '/finance/cash/list',
          permission: 'Financial.TakeOutLogs'
        },
        {
          name: '平台收益',
          url: '/finance/earningsLogs',
          permission: 'Financial.PlatformProfit'
        }
      ]
    },
    {
      name: '设备管理',
      url: '',
      permission: 'Device',
      icon: 'el-icon-receiving',
      children: [
        {
          name: '设备管理',
          url: '/device/list',
          permission: 'Device.View'
        },
        {
          name: '型号管理',
          url: '/device/types',
          permission: 'Device.TypeView'
        },
        {
          name: '终端管理',
          url: '/device/terminals',
          permission: 'Device.View'
        }, {
          name: '返厂记录',
          url: '/device/returnFactory',
          permission: 'Device.View'
        }
      ]
    },
    {
      name: '商家管理',
      url: '',
      permission: 'Merchant',
      icon: 'el-icon-eleme',
      children: [
        {
          name: '商家列表',
          url: '/merchant/list',
          permission: 'Merchant.View'
        },
        {
          name: '商家类别',
          url: '/merchant/categorys',
          permission: 'Merchant.CategoryView'
        },
        {
          name: '营收统计',
          url: '/merchant/statistics',
          permission: 'Merchant.CategoryView'
        }
      ]
    },
    {
      name: '代理管理',
      url: '',
      permission: 'Agent',
      icon: 'el-icon-office-building',
      children: [
        {
          name: '代理列表',
          url: '/agent/list',
          permission: 'Agent.View'
        },
        {
          name: '任务日志',
          url: '/agent/task',
          permission: 'Agent.TaskAmountLogs'
        },
        {
          name: '等级日志',
          url: '/agent/level',
          permission: 'Agent.LevelLogs'
        }
      ]
    },

    {
      name: '会员管理',
      url: '',
      permission: 'Member',
      icon: 'el-icon-user',
      children: [
        {
          name: '会员列表',
          url: '/member/list',
          permission: 'Member.View'
        },
        {
          name: '问题反馈',
          url: '/member/questionback',
          permission: 'Member.Feedback'
        },
        {
          name: '余额日志',
          url: '/member/capitallogs',
          permission: 'Member.BalanceLogs'
        },
        {
          name: '冻结日志',
          url: '/member/freezelogs',
          permission: 'Member.FrozenLogs'
        }
      ]
    },
    {
      name: '文章管理',
      url: '',
      permission: 'Article',
      icon: 'el-icon-document',
      children: [
        {
          name: '分类管理',
          url: '/article/classify',
          permission: 'Article.Manage'
        },
        {
          name: '文章列表',
          url: '/article/list',
          permission: 'Article.View'
        }
      ]
    },
    {
      name: '系统管理',
      url: '',
      permission: 'System',
      icon: 'iconfont icon-setting',
      children: [

        {
          name: '角色管理',
          url: '/system/roles',
          permission: 'System.RoleManage'
        },
        {
          name: '用户管理',
          url: '/system/users',
          permission: 'System.AdminManage'
        },
        {
          name: '系统配置',
          icon: 'iconfont icon-setting',
          url: '/system/settings',
          permission: 'System.AdminManage'
        },
        {
          name: '消息通知',
          url: '/system/appnotification',
          permission: 'System.AdminManage'
        },
        {
          name: '登录日志',
          url: '/system/loginlogs',
          permission: 'System.LoginLogs'
        },
        {
          name: '操作日志',
          url: '/system/handlelogs',
          permission: 'System.HandleLogs'
        },

      ]
    },
    {
      name: '广告管理',
      permission: 'Ad',
      icon: 'el-icon-copy-document',
      children: [
        {
          name: '广告管理',
          url: '/advert',
          permission: "Ad.Manage"
        }
      ]
    },
    {
      name: '客户端配置',

      permission: 'ClientConfig',
      icon: 'el-icon-discount',
      children: [
        {
          name: '小程序配置',
          url: '/setconfig/list',
          permission: "ClientConfig.Update"
        }
      ]
    }
  ]
}
