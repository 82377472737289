
























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import navs from "@/router/navs";
import utils from "@/libs/utils";
import { Route } from "vue-router";
import * as appRegion from "@/libs/app_region";

@Component
export default class Admin extends Vue {
  height: number = 200;
  fullscreen: boolean = false;
  isCollapse: boolean = false;
  changePasswordDialogVisible: boolean = false;
  menus: any[] = [];
  model: any = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  };
  currTab: any = null;
  rules: any = {
    currentPassword: [
      { required: true, message: "请输入旧密码", trigger: "blur" },
      { min: 6, max: 32, message: "请输入6-32位旧密码", trigger: "blur" }
    ],
    newPassword: [
      { required: true, message: "请输入新密码", trigger: "blur" },
      { min: 6, max: 32, message: "请输入6-32位新密码", trigger: "blur" }
    ],
    confirmPassword: [
      { required: true, message: "请输入确认密码", trigger: "blur" },
      {
        validator: (rule: any, value: string, callback: Function) => {
          if (value !== this.model.newPassword) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        },
        trigger: "blur"
      }
    ]
  };

  // 标签页：
  activeUrl: string = "";
//   tabs数组
  tabs: any[] = [
    {
      name: "首页",
      url: "/",
      query: {},
      component: "dashboard",
      closable: false
    }
  ];
  includePageNames: string[] = ["dashboard","全部"]; // 要缓存的组件名称，移出includePageNames则立即清除缓存， 组件名称要和路由名称一样，这里只能读路由名称，读不到组件名称

  loading: boolean = false;
  loaded: boolean = false;
  async created() {
    let loading = this.$loading({
      lock: true,
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.3)"
    });
    try {
      // 获取枚举值
         await this.loadAllEnums();
        //  获取省市区
      await appRegion.init();
     
      await this.setLoginInfo();
      this.initTab();
    } catch (error) {}
    this.loaded = true;
    loading.close();
  }
// 获取枚举 在存到store
  async loadAllEnums() {
    try {
      var res = await this.$ajax.get("/api/services/app/Shared/GetAllEnums");
      this.$store.commit("setAllEnums", res.data.result);
    } catch (error) {
      this.$message.error("获取数据失败，请刷新后再试");
    }
  }

// 获取store里面的权限
  get permissions() {
    console.log(this.$store.state);
    return this.$store.state.admin.permissions;
  }             


  @Watch("permissions")
  onPermissionsChange(myPermissions: any[]) {
    // 循环出navs里面的路由
    this.menus = navs.adminmenus.filter(x => {
      let item = x;
      if (x.children && x.children.length > 0) {
        item.children = x.children.filter(
          i => !i.permission || myPermissions.indexOf(i.permission) >= 0
        );
      }
      return !item.permission || myPermissions.indexOf(item.permission) > -1;
    });    
  }
// 获取store sysPagerHeader
  get pagerHeader() {
    return this.$store.state.sysPagerHeader;
  }
//   路由拦截
  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    this.appendTab(to);
    next();
  }
  // 首次进入时设置第一个标签页（url跳转 刷新时）
  initTab() {
    let to = this.$route;
    this.appendTab(to);
  }
//   添加到tags数组中去
  appendTab(to: Route) {
    let component = to.matched.filter(x => x.path === to.path)[0].components
      .default.name;

    //创建页面后，如果tabs里面没有当前页，就加进去。
    if (this.tabs.filter(x => x.url === to.path).length === 0) {
      this.tabs.push({
        name: to.name,
        url: to.path,
        query: to.query,
        component: component,
        closable: true
      });
    }

    if (this.includePageNames.filter(x => x === component).length === 0) {
      this.includePageNames.push(component!);
    }
    this.activeUrl = to.path;
    this.currTab = { url: to.path, name: to.name }; //只是用来关闭当前标签页
  }
//   点击删除tags
  closeCurrTab(toUrl: string) {
    this.closeTab(this.currTab, toUrl);
  }
//   切换tabg
  changeTab(item: any) {
    this.$router.push({ path: item.url, query: item.query });
  }
//   删除tags
  closeTab(item: any, toUrl: string) {
    if (this.tabs.length === 1) {
      this.$message.warning("最后一个标签页不能删除");
      return;
    }
    let index = this.tabs.findIndex(x => x.url === item.url);
    this.tabs.splice(index, 1);
    let index2 = this.includePageNames.findIndex(x => x === item.name);
    this.includePageNames.splice(index2, 1);
    if (toUrl) {
      this.$router.push(toUrl);
    } else {
      let _item = this.tabs[index] || this.tabs[index - 1];
      this.$router.push({ path: _item.url, query: _item.query });
    }
  }
    // 点击收起菜单
  collapseToggle() {
    this.isCollapse = !this.isCollapse;
  }
  handleOpen() { 
  }
  handleClose() {}
// 退出登陆  并删除store的数据
  logout() {
    this.$confirm("确定要退出登录状态吗？", "", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        this.$store.dispatch("adminLogout");
        this.menus = [];
        this.$router.push({ path: "/login" });
        console.log("退出后admin信息：");
        //console.log(this.$store.state.admin)
        this.$notify({
          title: "退出成功",
          message: "您已成功退出系统",
          type: "success"
        });
      })
      .catch(() => {});
  }
//   修改密码
  changePassword() {
    (this.$refs.loginForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          let res = await this.$ajax.post(
            "/api/services/app/User/ChangePassword",
            this.model
          );
          if (res.data.success) {
            this.$notify({
              title: "修改成功",
              message: "请重新登陆！",
              type: "success",
              duration: 2000
            });
            this.$store.dispatch("adminLogout");
            this.$router.push({ path: "/login" });
          }
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    });
  }
//   获取用户信息 和获取权限和版本什么的 然后在存store
  async setLoginInfo() {
    // todo 如果是打包员且是移动端就直接跳转到打包页面。 Order.ParcelPackManage
    let loading = this.$loading({
      lock: true,
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.3)"
    });
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Session/GetCurrentLoginInformations"
      );

      if (res.data.success) {
        loading.close();
        if (!res.data.result.user) {
          const that = this;
          return void this.$message({
            message: "您没有登录",
            type: "warning",
            onClose() {
              that.$router.replace("/login");
            }
          });
        }
        // 权限
        var permissions =  res.data.result.permissions;
      
        
        this.$store.dispatch("setAdmin", {
          id: res.data.result.user.id,
          userName: res.data.result.user.userName,
          name: res.data.result.user.name,
          emailAddress: res.data.result.user.emailAddress,
          permissions: permissions,
          tenant: res.data.result.tenant
        });

        if (
          res.data.result.permissions.indexOf("ShopBusiness.ShopPack") >= 0 &&
          /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        ) {
          //this.$router.replace({ path: '/orders/packages' })
          window.location.href = "/orders/packages";
        }
      }
    } catch (error) {
      console.log(error);
    }

    loading.close();
  }
  // 全屏事件
  handleFullScreen() {
    let element = document.documentElement as any;
    if (this.fullscreen) {
      let doc = document as any;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitCancelFullScreen) {
        doc.webkitCancelFullScreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    }
    this.fullscreen = !this.fullscreen;
  }
  // beforeRouteEnter(to: Route, from: Route, next: Function) {
  //   console.log(arguments);
  //   next(true);
  // }
}
